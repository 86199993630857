<template>
  <div>
    <!--begin::Signup-->
    <div class="login-form login-signin">
      <div class="mb-10 mb-lg-10">
        <h3 class="font-size-h1 font-weight-normal">비밀번호 재설정</h3>
        <p class="font-weight-semi-bold font-size-lg"></p>
      </div>
      <!--begin::Form-->
      <div class="form" v-show="!pwdState">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label>아이디 확인</label>
          </div>
          <b-input-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="id"
            type="text"
            placeholder="ID"
            :state='id.toString().length == 0 ? null: true'
            maxlength='30'
          ></b-form-input>
          <b-input-group-append>
            <b-button class="h-auto py-5 px-6" variant="secondary" @click="idCheck()">{{idConfirm ? '재확인' :'아이디 확인'}}</b-button>
          </b-input-group-append>
        </b-input-group>
        </b-form-group>

        <b-form-group v-show="idConfirm">
          <div class="d-flex justify-content-between">
            <label>이메일 확인</label>
          </div>
          <b-input-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="email"
            type="text"
            placeholder="Email address"
            :state = emailState
            maxlength='30'
            disabled
          ></b-form-input>
          <b-input-group-append>
            <b-button class="h-auto py-5 px-6" variant="secondary" @click="sendEmail()">{{confirmCodeState ? '재전송':'인증번호 전송'}}</b-button>
          </b-input-group-append>
        </b-input-group>
        </b-form-group>

        <b-form-group v-show="confirmCodeState && idConfirm">
          <div class="d-flex justify-content-between">
            <label>인증번호 확인</label>
          </div>
        <b-input-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="confirmCode"
            type="text"
            placeholder="Confirm Code"
            maxlength='50'
          ></b-form-input>
          <b-input-group-append :class="`h-auto py-5 px-6 bg-secondary rounded-right ${setTime==0? 'text-danger' : ''}`">
            {{confirmTime}}
          </b-input-group-append>
        </b-input-group>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-center">
          <b-button
            variant="primary"
            ref="signupBtn"
            class="px-9 py-4 my-3 mr-3"
          >
            <span class="font-weight-bold font-size-h5" @click="checkConfirmCode()">인증번호 입력</span>
          </b-button>
          <b-button
            variant="light-primary"
            class="px-9 py-4 my-3"
            v-on:click="$router.push('signin')"
          >
            <span class="font-weight-bold font-size-h5">취소</span>
          </b-button>
        </div>
        <!--end::Action-->
      </div>
      <!--end::Form-->

      <!--begin::Form-->
      <div class="form" v-show="pwdState">
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label>새 비밀번호</label>
          </div>
          <b-input-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="newPwd"
            type="password"
            placeholder="Password"
            :state = newPwdState
            maxlength='30'
          ></b-form-input>
        </b-input-group>
        </b-form-group>

        <b-form-group>
          <div class="d-flex justify-content-between">
            <label>새 비밀번호 확인</label>
          </div>
        <b-input-group>
          <b-form-input
            class="form-control form-control-solid h-auto py-5 px-6"
            v-model="newPwdConfirm"
            type="password"
            placeholder="Password Confirm"
            :state = newPwdConfirmState
            maxlength='30'
          ></b-form-input>
        </b-input-group>
        </b-form-group>

        <!--begin::Action-->
        <div class="form-group d-flex flex-center">
          <b-button
            variant="primary"
            ref="signupBtn"
            class="px-9 py-4 my-3 mr-3"
          >
            <span class="font-weight-bold font-size-h5" @click="pwdReset()">비밀번호 변경</span>
          </b-button>
          <b-button
            variant="light-primary"
            class="px-9 py-4 my-3"
            v-on:click="$router.push('signin')"
          >
            <span class="font-weight-bold font-size-h5">취소</span>
          </b-button>
        </div>
        <!--end::Action-->
      </div>
      <!--end::Form-->
    </div>
    <!--end::Signup-->
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import Vue from 'vue';
import {ACT_ADD_ALERT} from '@/core/services/store/alert.module';
import {SEND_EMAIL_PWD_RESET, CONFIRM_PWD_RESET, UPDATE_PWD_RESET, GET_MEMBER_INFO} from '@/core/services/store/auth.module';
import {isSuccess, getItem} from '@/core/services/funcs';



export default {
  name: "pwdReset",
  data() {
    return {
        email: '',
        emailOriginal: '',
        emailState: null,
        confirmCode: '',
        confirmCodeState: false,
        id: '',
        idConfirm: false,
        setTime: 180,
        confirmTime: '3분 0초',
        // eslint-disable-next-line
        emailRegex: /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/,

        pwdState: false,
        newPwd: '',
        newPwdState: null,
        newPwdConfirm: '',
        newPwdConfirmState: null,
    }
  },
  watch:{
    'email'(){
      if(this.email == ''){
        this.emailState = null
      }else if(!this.emailRegex.test(this.email)){
        this.emailState = false
      }else{
        this.emailState = true
      }
    },
    'newPwd'(){
      if(this.newPwd == ''){
        this.newPwdState = null
      }else if(this.newPwd.toString()<3 || this.newPwd != this.newPwdConfirm){
        this.newPwdState = false
        this.newPwdConfirmState = false
      }else{
        this.newPwdState = true
        this.newPwdConfirmState = true
      }
    },
    'newPwdConfirm'(){
      if(this.newPwdConfirm == ''){
        this.newPwdConfirmState = null
      }else if(this.newPwdConfirm.toString()<3 || this.newPwd != this.newPwdConfirm){
        this.newPwdState = false
        this.newPwdConfirmState = false
      }else{
        this.newPwdState = true
        this.newPwdConfirmState = true
      }
    }
  },
  methods: {
    idCheck(){
      this.$store.dispatch(GET_MEMBER_INFO, this.id).then(resp =>{
        if(resp.items.length != 0){
          this.$store.dispatch(ACT_ADD_ALERT, {message: '아이디가 확인되었습니다.', color: 'success'});
          this.email = getItem(resp).memberEmail
          this.emailOriginal = getItem(resp).memberEmail
          this.idConfirm = true
          this.sendEmail()
        }else{
          this.$store.dispatch(ACT_ADD_ALERT, {message: '일치하는 아이디가 없습니다.', color: 'danger'});
          this.idConfirm = false
          this.confirmCodeState = false
        }
      })
    },
    sendEmail(){
      if(this.email && this.emailRegex.test(this.email) && this.email === this.emailOriginal){
        this.$store.dispatch(SEND_EMAIL_PWD_RESET, {email: this.email}).then(()=>{
          this.$store.dispatch(ACT_ADD_ALERT, {message: '가입하신 이메일에서 인증번호를 확인해주세요.', color: 'success'});
        })
        this.confirmCodeState = true
        this.setTime = 180
        clearInterval(this.timer)
        this.timer = setInterval(()=>{
          this.confirmTimeSet()
        },1000)
      }else{
        this.$store.dispatch(ACT_ADD_ALERT, {message: '가입하신 이메일을 올바르게 입력해주세요.', color: 'danger'});
      }
    },
    confirmTimeSet(){
      this.confirmTime = Math.floor(this.setTime / 60) + "분 " + (this.setTime % 60) + "초"
      this.setTime--
      if(this.setTime == 0){
        clearInterval(this.timer)
        this.confirmTime = '인증시간 만료'
      }
    },
    checkConfirmCode(){
      if(this.setTime == 0){
        this.$store.dispatch(ACT_ADD_ALERT, {message: '인증시간이 만료되었습니다.', color: 'danger'});
        return
      }else{
        let params = {
          email: this.email,
          confirmToken: this.confirmCode,
          confirmType: 1
        }

        this.$store.dispatch(CONFIRM_PWD_RESET, params).then(resp =>{
          if(isSuccess(resp)){
            this.$store.dispatch(ACT_ADD_ALERT, {message: '인증이 완료되었습니다. 비밀번호를 재설정해 주세요.', color: 'success'});
            this.pwdState = true
          }else{
            this.$store.dispatch(ACT_ADD_ALERT, {message: '인증번호를 올바르게 입력해주세요.', color: 'danger'});
          }
        })
      }
    },
    pwdReset(){
      if(this.newPwdState && this.newPwdConfirmState){
        if(this.newPwd == this.newPwdConfirm && this.newPwd.toString().length>=3){
          let params = {
            email: this.email,
            memberId: this.id,
            memberPwd: Vue.CryptoJS.SHA256(this.newPwd).toString(Vue.CryptoJS.enc.Hex),
            confirmToken: this.confirmCode
          }
          this.$store.dispatch(UPDATE_PWD_RESET, params).then(resp =>{
            if(isSuccess(resp)){
              this.$store.dispatch(ACT_ADD_ALERT, {message: '비밀번호 변경이 완료되었습니다.', color: 'success'});
              this.$router.push('signin')
            }else{
              this.$store.dispatch(ACT_ADD_ALERT, {message: '비밀번호를 올바르게 입력해주시기 바랍니다.', color: 'danger'});
            }
          })
        } else {
          this.$store.dispatch(ACT_ADD_ALERT, {message: '비밀번호는 3자 이상 입력해주시기 바랍니다.', color: 'danger'});
        }
      }else{
        this.$store.dispatch(ACT_ADD_ALERT, {message: '비밀번호를 올바르게 입력해주시기 바랍니다.', color: 'danger'});
      }
    },
  }
};
</script>
